<script>
  import PhotoSwipe from "PhotoSwipe";
  let ItemAnimation = false;
  const handleMouseenter = () => (ItemAnimation = true);
  const handleMouseleave = () => (ItemAnimation = false);


  function openPhotos(thisID) {
    var pswpElement = document.querySelectorAll(".pswp")[0];
    var items = [
      {
        src: "/PDF/CERT_1.png",
        w: 1600,
        h: 2200
      },
      {
        src: "/PDF/CERT_2.png",
        w: 1600,
        h: 2200
      },
      {
        src: "/PDF/CERT_3.png",
        w: 1600,
        h: 2200
      },
      {
        src: "/PDF/CERT_4.png",
        w: 1600,
        h: 2200
      },
      {
        src: "/PDF/CERT_5.png",
        w: 1600,
        h: 2200
      },
      {
        src: "/PDF/CERT_6.png",
        w: 1600,
        h: 2200
      }
    ];

    // define options (if needed)
    var options = {
      // optionName: 'option value'
      // for example:
      index: thisID // start at first slide
    };

    var gallery = new PhotoSwipe(
      pswpElement,
      PhotoSwipeUI_Default,
      items,
      options
    );
    gallery.init();
    // gallery.goTo(thisID);
  }

</script>

<style>
  .floating {
    animation-name: floating;
    animation-duration: 1.911s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
  }

  @keyframes floating {
    from {
      transform: translate(0, 0px);
    }
    50% {
      transform: translate(0, 45px);
    }
    to {
      transform: translate(0, -0px);
    }
  }


</style>

<div id="more-features" class="container-fluid">
  <div class="container" id="Technology">
    <div class="row">
      <div class="col-md-12">
        <div class="row-title">Smart Cooling Technology.</div>
      </div>
    </div>
    <div
      class="row"
      class:floating={ItemAnimation}
      on:mouseenter={handleMouseenter}
      on:mouseleave={handleMouseleave}>
      <div class="col-sm-6 col-md-4">
        <div class="mfeature-box">
          <div class="mfeature-icon">
            <div class="icon-img TechnologyImg">
              <img src="images/Group 235@2x.png" alt="" />
            </div>
          </div>

        </div>
      </div>
      <div class="col-sm-6 col-md-4">
        <div class="mfeature-box">
          <div class="mfeature-icon">
            <div class="icon-img TechnologyImg">
              <img src="images/Group 236@2x.png" alt="" />
            </div>
          </div>

        </div>
      </div>
      <div class="col-sm-6 col-md-4">
        <div class="mfeature-box">
          <div class="mfeature-icon">
            <div class="icon-img TechnologyImg">
              <img src="images/Group 237@2x.png" alt="" />
            </div>
          </div>

        </div>
      </div>

    </div>
    <div class="row">
      <div class="col-md-8">
        <p class="TechnologyList">
          -Save chiller energy up to 37%.
          <br />
          -Increase cooling capacity by 41%.
          <br />
          -Tested and approved in all climate zones and conditions.
          <br />
          -15 years experience.
          <br />
        </p>
      </div>
      <div class="col-md-4">
        <img
          class="subTecImg"
          src="images/Group 238@2x.png"
          alt=""
          style="width:70%;" />
      </div>

    </div>

  </div>
</div>

<div id="more-features" class="container-fluid">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="row-title">Duct Insulation.</div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6 col-md-3">
        <div class="mfeature-box DuctInsulationBox">
          <div class="mfeature-icon">
            <div class="icon-img DuctInsulationBoxImg">
              <img src="images/Duct_Insulation1.png" alt="" />
            </div>
          </div>

        </div>
      </div>
      <div class="col-sm-6 col-md-3">
        <div class="mfeature-box DuctInsulationBox">
          <div class="mfeature-icon">
            <div class="icon-img DuctInsulationBoxImg">
              <img src="images/Duct_Insulation2.png" alt="" />
            </div>
          </div>

        </div>
      </div>
      <div class="col-sm-6 col-md-3">
        <div class="mfeature-box DuctInsulationBox">
          <div class="mfeature-icon">
            <div class="icon-img DuctInsulationBoxImg">
              <img src="images/Duct_Insulation3.png" alt="" />
            </div>
          </div>

        </div>
      </div>
      <div class="col-sm-6 col-md-3">
        <div class="mfeature-box DuctInsulationBox ">
          <div class="mfeature-icon">
            <div class="icon-img DuctInsulationBoxImg">
              <img src="images/Duct_Insulation4.png" alt="" />
            </div>
          </div>

        </div>
      </div>

    </div>

  </div>
</div>

<div id="more-features" class="container-fluid coolBG2">
  <div class="container">

    <div class="row">
      <div class="col-md-6 col-sm-12">
        <div class="row-title2">Corrective Maintenance.</div>
        <div>
          <p class="AsimpleText">
            The corrective maintenance may be of routine or emergency nature.
            While all the routine repair jobs shall be scheduled in our normal
            daily plan that are executed during normal working hours all
            emergency calls would be responded on priority basis. It is
            understood that the need for an emergency service call would arise
            in case a piece of equipment encounters a sudden failure. We fully
            cover all labor and transportation required to carry out any
            corrective maintenance throughout the phase.
          </p>
        </div>
      </div>
      <div class="col-md-6 col-sm-12">
        <div>
          <img
            class="subTecImg"
            src="images/hvac_service_man_1400_7f4_A0_Rectangle_126_pattern.png"
            alt=""
            style=" width: 100%; " />
        </div>
      </div>
    </div>
  </div>
</div>

<div id="more-features" class="container-fluid coolBG3">
  <div class="container">

    <div class="row">
      <div class="col-md-6 col-sm-12">
        <img
          class="subTecImg"
          src="images/Should_I_Repair_or_Replace_My__A0_Rectangle_129_pattern.png"
          alt=""
          style=" width: 100%; " />
      </div>
      <div class="col-md-6 col-sm-12">
        <div class="row-title2">Periodic Preventive Maintenance.</div>
        <div>
          <p class="AsimpleText">
            Depending upon the type of equipment each PPM visit will include
            undertaking of multiple tasks. Designed to ensure a holistic
            maintenance approach, PPM visits protect your equipment and prolong
            their services and enhance them.
          </p>
        </div>
      </div>

    </div>
  </div>
</div>

<div id="more-features" class="container-fluid">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="row-title">Get Smarter!</div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6 col-md-4">
        <div class="mfeature-box">
          <div class="mfeature-icon">
            <div class="icon-img ">
              <img src="svg/GetSmarter3.svg" alt="" style=" width: 80px; " />
            </div>
          </div>
          <div class="mfeature-title">Supply and install smart devices</div>
        </div>
      </div>
      <div class="col-sm-6 col-md-4" alt="Borat">
        <div class="mfeature-box">
          <div class="mfeature-icon">
            <div class="icon-img ">
              <img src="svg/GetSmarter4.svg" alt="" style=" width: 80px; " />
            </div>
          </div>
          <div class="mfeature-title">
            Ensure to minimize the waste of electrical consumptions.
          </div>
        </div>
      </div>
      <div class="col-sm-6 col-md-4">
        <div class="mfeature-box">
          <div class="mfeature-icon">
            <div class="icon-img ">
              <img src="svg/GetSmarter1.svg" alt="" style=" width: 80px; " />
            </div>
          </div>
          <div class="mfeature-title">Stop the misuse by the users.</div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6 col-md-4">
        <div class="mfeature-box">
          <div class="mfeature-icon">
            <div class="icon-img ">
              <img src="svg/GetSmarter2.svg" alt="" style=" width: 80px; " />
            </div>
          </div>
          <div class="mfeature-title">
            Schedule AC systems based on working hours and requirements.
          </div>
        </div>
      </div>
      <div class="col-sm-6 col-md-4" alt="Borat">
        <div class="mfeature-box">
          <div class="mfeature-icon">
            <div class="icon-img ">
              <img src="svg/GetSmarter5.svg" alt="" style=" width: 80px; " />
            </div>
          </div>
          <div class="mfeature-title">
            Set cooling temperature as management requirements.
          </div>
        </div>
      </div>
      <div class="col-sm-6 col-md-4">
        <div class="mfeature-box">
          <div class="mfeature-icon">
            <div class="icon-img ">
              <img src="svg/GetSmarter6.svg" alt="" style=" width: 80px; " />
            </div>
          </div>
          <div class="mfeature-title">Wired / wireless products.</div>
        </div>
      </div>
    </div>

  </div>
</div>

<div id="more-features" class="container-fluid coolBG2">
  <div class="container">

    <div class="row">
      <div class="col-md-6 col-sm-12">
        <div class="row-title2">System General Features.</div>
        <div>
          <p class="AsimpleText">Dashboard and analytics.</p>
        </div>
      </div>
      <div class="col-md-6 col-sm-12">
        <div>
          <img
            class="subTecImg"
            src="images/Laptop1.png"
            alt=""
            style=" width: 100%; " />
        </div>
      </div>
    </div>
  </div>
</div>
<div id="more-features" class="container-fluid coolBG3">
  <div class="container">

    <div class="row">
      <div class="col-md-6 col-sm-12">
        <img
          class="subTecImg"
          src="images/Laptop2.png"
          alt=""
          style=" width: 100%; " />
      </div>
      <div class="col-md-6 col-sm-12">
        <div class="row-title2">System General Features.</div>
        <div>
          <p class="AsimpleText">Scheduling and control portal.</p>
        </div>
      </div>

    </div>
  </div>
</div>
<div id="more-features" class="container-fluid">
  <div class="container" id="Client">
    <div class="row">
      <div class="col-md-12">
        <div class="row-title">Our Clinets</div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6 col-md-2 col-md-offset-1">
        <div class="mfeature-box DuctInsulationBox">
          <div class="mfeature-icon">
            <div class="icon-img DuctInsulationBoxImg">
              <img
                src="images/client1.png"
                alt=""
                style=" max-width: 160px; width: 125% !important; max-height:
                130px; " />
            </div>
          </div>

        </div>
      </div>
      <div class="col-sm-6 col-md-2">
        <div class="mfeature-box DuctInsulationBox">
          <div class="mfeature-icon">
            <div class="icon-img DuctInsulationBoxImg">
              <img
                src="images/client2.png"
                alt=""
                style=" max-width: 160px; width: 125% !important; max-height:
                130px; " />
            </div>
          </div>

        </div>
      </div>
      <div class="col-sm-6 col-md-2">
        <div class="mfeature-box DuctInsulationBox">
          <div class="mfeature-icon">
            <div class="icon-img DuctInsulationBoxImg">
              <img
                src="images/client3.png"
                alt=""
                style=" max-width: 160px; width: 125% !important; max-height:
                130px; " />
            </div>
          </div>

        </div>
      </div>
      <div class="col-sm-6 col-md-2">
        <div class="mfeature-box DuctInsulationBox ">
          <div class="mfeature-icon">
            <div class="icon-img DuctInsulationBoxImg">
              <img
                src="images/client4.png"
                alt=""
                style=" max-width: 160px; width: 125% !important; max-height:
                130px; " />
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-6 col-md-2">
        <div class="mfeature-box DuctInsulationBox ">
          <div class="mfeature-icon">
            <div class="icon-img DuctInsulationBoxImg">
              <img
                src="images/client5.png"
                alt=""
                style=" max-width: 160px; width: 125% !important; max-height:
                130px; " />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6 col-md-2 col-md-offset-1">
        <div class="mfeature-box DuctInsulationBox ">
          <div class="mfeature-icon">
            <div class="icon-img DuctInsulationBoxImg">
              <img
                src="images/client6.png"
                alt=""
                style=" max-width: 160px; width: 125% !important; max-height:
                130px; " />
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-6 col-md-2">
        <div class="mfeature-box DuctInsulationBox ">
          <div class="mfeature-icon">
            <div class="icon-img DuctInsulationBoxImg">
              <img
                src="images/client7.png"
                alt=""
                style=" max-width: 160px; width: 125% !important; max-height:
                130px; " />
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-6 col-md-2">
        <div class="mfeature-box DuctInsulationBox ">
          <div class="mfeature-icon">
            <div class="icon-img DuctInsulationBoxImg">
              <img
                src="images/client8.png"
                alt=""
                style=" max-width: 160px; width: 125% !important; max-height:
                130px; " />
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-6 col-md-2">
        <div class="mfeature-box DuctInsulationBox ">
          <div class="mfeature-icon">
            <div class="icon-img DuctInsulationBoxImg">
              <img
                src="images/client9.png"
                alt=""
                style=" max-width: 160px; width: 125% !important; max-height:
                130px; " />
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-6 col-md-2">
        <div class="mfeature-box DuctInsulationBox ">
          <div class="mfeature-icon">
            <div class="icon-img DuctInsulationBoxImg">
              <img
                src="https://www.pinclipart.com/picdir/big/92-928365_doordash-food-delivery-draw-dunkin-donuts-logo-clipart.png"
                alt=""
                style=" max-width: 160px; width: 135% !important; max-height:
                130px; " />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6 col-md-2 col-md-offset-1">
        <div class="mfeature-box DuctInsulationBox ">
          <div class="mfeature-icon">
            <div class="icon-img DuctInsulationBoxImg">
              <img
                src="images/client11.png"
                alt=""
                style=" max-width: 160px; width: 125% !important; max-height:
                130px; " />
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-6 col-md-2">
        <div class="mfeature-box DuctInsulationBox ">
          <div class="mfeature-icon">
            <div class="icon-img DuctInsulationBoxImg">
              <img
                src="images/client12.png"
                alt=""
                style=" max-width: 160px; width: 125% !important; max-height:
                130px; " />
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-6 col-md-2">
        <div class="mfeature-box DuctInsulationBox ">
          <div class="mfeature-icon">
            <div class="icon-img DuctInsulationBoxImg">
              <img
                src="images/client13.png"
                alt=""
                style=" max-width: 160px; width: 135% !important; max-height:
                130px; " />
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-6 col-md-2">
        <div class="mfeature-box DuctInsulationBox ">
          <div class="mfeature-icon">
            <div class="icon-img DuctInsulationBoxImg">
              <img
                src="images/client14.png"
                alt=""
                style=" max-width: 160px; width: 125% !important; max-height:
                130px; " />
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-6 col-md-2">
        <div class="mfeature-box DuctInsulationBox ">
          <div class="mfeature-icon">
            <div class="icon-img DuctInsulationBoxImg">
              <img
                src="images/client15.png"
                alt=""
                style=" max-width: 160px; width: 125% !important; max-height:
                130px; " />
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>
<div id="more-features" class="container-fluid">
  <div class="container" id="Client">
    <div class="row">
      <div class="col-md-12">
        <div class="row-title">Certificate</div>
      </div>
    </div>
    <div class="row">
      <div
        class="col-sm-6 col-md-3 col-md-offset-1"
        on:click={() => openPhotos(0)}>
        <div class="mfeature-box DuctInsulationBox">
          <div class="mfeature-icon">
            <div class="icon-img DuctInsulationBoxImg">
              <img
                src="PDF/CERT_1.png"
                alt=""
                style=" width: 100% !important; " />
            </div>
          </div>

        </div>
      </div>
      <div class="col-sm-6 col-md-3" on:click={() => openPhotos(1)}>
        <div class="mfeature-box DuctInsulationBox">
          <div class="mfeature-icon">
            <div class="icon-img DuctInsulationBoxImg">
              <img
                src="PDF/CERT_2.png"
                alt=""
                style=" width: 100% !important; " />
            </div>
          </div>

        </div>
      </div>
      <div class="col-sm-6 col-md-3" on:click={() => openPhotos(2)}>
        <div class="mfeature-box DuctInsulationBox">
          <div class="mfeature-icon">
            <div class="icon-img DuctInsulationBoxImg">
              <img
                src="PDF/CERT_3.png"
                alt=""
                style="width: 100% !important; " />
            </div>
          </div>

        </div>
      </div>

    </div>
    <div class="row">
      <div
        class="col-sm-6 col-md-3 col-md-offset-1"
        on:click={() => openPhotos(3)}>
        <div class="mfeature-box DuctInsulationBox">
          <div class="mfeature-icon">
            <div class="icon-img DuctInsulationBoxImg">
              <img
                src="PDF/CERT_4.png"
                alt=""
                style=" width: 100% !important; " />
            </div>
          </div>

        </div>
      </div>
      <div class="col-sm-6 col-md-3" on:click={() => openPhotos(4)}>
        <div class="mfeature-box DuctInsulationBox">
          <div class="mfeature-icon">
            <div class="icon-img DuctInsulationBoxImg">
              <img
                src="PDF/CERT_5.png"
                alt=""
                style=" width: 100% !important; " />
            </div>
          </div>

        </div>
      </div>
      <div class="col-sm-6 col-md-3" on:click={() => openPhotos(5)}>
        <div class="mfeature-box DuctInsulationBox">
          <div class="mfeature-icon">
            <div class="icon-img DuctInsulationBoxImg">
              <img
                src="PDF/CERT_6.png"
                alt=""
                style="width: 100% !important; " />
            </div>
          </div>

        </div>
      </div>

    </div>

  </div>
</div>
<!-- <div id="more-features" class="container-fluid">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="row-title">Certificates</div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="owl-carousel">
          <div class="item">
            <img src="images/Certificates1.png" style="width: 320px; " alt="" />
          </div>
          <div class="item">
            <img src="images/Certificates2.png" style="width: 320px; " alt="" />
          </div>
          <div class="item">
            <img src="images/Certificates3.png" style="width: 320px; " alt="" />
          </div>
          <div class="item">
            <img src="images/Certificates4.png" style="width: 320px; " alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</div> -->
