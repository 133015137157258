<script>
  import { quintOut } from "svelte/easing";
  import { crossfade } from "svelte/transition";
  import { flip } from "svelte/animate";

  const [send, receive] = crossfade({
    duration: d => Math.sqrt(d * 300),
    fallback(node, params) {
      const style = getComputedStyle(node);
      const transform = style.transform === "none" ? "" : style.transform;

      return {
        duration: 600,
        easing: quintOut,
        css: t => `
                transform: ${transform} scale(${t});
                opacity: ${t}
            `
      };
    }
  });
  let ItemAnimation = false;
  const handleMouseenter = () => (ItemAnimation = true);
  const handleMouseleave = () => (ItemAnimation = false);
</script>

<style>
  .floating {
    animation-name: floating;
    animation-duration: 1.911s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
  }

  @keyframes floating {
    from {
      transform: translate(0, 0px);
    }
    50% {
      transform: translate(0, 45px);
    }
    to {
      transform: translate(0, -0px);
    }
  }
</style>

<div id="more-features" class="container-fluid">
  <div class="container" id="OurServices">
    <div class="row">
      <div class="col-md-12">
        <div class="row-title">Our Services</div>
      </div>
    </div>
    <div
      class="row"
      class:floating={ItemAnimation}
      on:mouseenter={handleMouseenter}
      on:mouseleave={handleMouseleave}>
      <div class="col-sm-6 col-md-3">
        <div class="mfeature-box">
          <div class="mfeature-icon">
            <div class="icon-img ">
              <img src="svg/1.svg" alt="" />
            </div>
          </div>
          <div class="mfeature-title">
            Energy Savings
            <br />
            Products & Controls
          </div>
        </div>
      </div>
      <div class="col-sm-6 col-md-3" alt="Borat">
        <div class="mfeature-box">
          <div class="mfeature-icon">
            <div class="icon-img ">
              <img src="svg/4.svg" alt="" />
            </div>
          </div>
          <div class="mfeature-title">Smart Home & Offices</div>
        </div>
      </div>
      <div class="col-sm-6 col-md-3">
        <div class="mfeature-box">
          <div class="mfeature-icon">
            <div class="icon-img ">
              <img src="svg/3.svg" alt="" />
            </div>
          </div>
          <div class="mfeature-title" >
            Air conditioning 
            <br />
            <span style="font-size: 16px;">Replacement & Maintenance</span>
          </div>
        </div>
      </div>
      <div class="col-sm-6 col-md-3">
        <div class="mfeature-box">
          <div class="mfeature-icon">
            <div class="icon-img ">
              <img src="svg/2.svg" alt="" />
            </div>
          </div>
          <div class="mfeature-title">
            Air Curtains 
            <br />
               <span style="font-size: 16px;">Supply & Installations</span>
          </div>
        </div>
      </div>

    </div>

  </div>
</div>
<div id="more-features" class="container-fluid coolBG1">
  <div class="container ">
    <div class="row">
      <div class="col-sm-6 col-md-6">
        <!-- <div class="coolBG1Sub" /> -->
      </div>
      <div id="coolBG1Content" class="col-sm-6 col-md-6">
        <h1>Small ideas, Big Savings!</h1>
        <p>
          HVAC contributes almost 80% of a typical building or organization
          energy consumption. With the electricity inflation rates, HVAC is the
          main item where we should focus on! Replacement of old AC system is
          great, but will require a higher investment. But if you are looking
          for lower investments there are always smart ways to reduce wasted
          energy. Basically you need to focus on supplying and installing
          innovated solutions and controls. ESS since its stared is offering
          wide range of solutions in HVAC to boost the efficiency. We believe
          that if protection of natural resources goes wrong, nothing else will
          go right!
        </p>
      </div>

    </div>

  </div>
</div>
